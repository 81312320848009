
import styled from 'styled-components';
import colors from 'styles/colors';
import { StyledCard } from 'components/Form/Card';

const StyledSelfScanMsg = styled(StyledCard)`
  margin: 0px auto 1rem;
  width: 95vw;
  a { color: ${colors.primary}; }
  b { font-weight: extra-bold; }
  span, i { opacity: 0.85; }
  img {
    width: 5rem;
    float: right;
    border-radius: 4px;
  }
`;

const messages = [
  'We see you\'re still here, scanning your resources. Hopefully, your SSH is as secure as Fort Knox!',
  'Recursive scanning detected. The universe might implode...or it might not. But let\'s not try to find out.',
  'Hey, stop checking us out! We\'re blushing... 😉 But seriously, keep up the good work on security!',
  'Hmmm, scanning us, are you? We feel so special! Remember, security is the best accessory!',
  'Alert! Mirror scanning detected. Trust us, we\'re looking good 😉. But seriously, keep your scans sharp!',
  'We\'re flattered you\'re trying to scan us, but we can\'t tickle ourselves! Keep those scans thorough!',
  'Oh, inspecting the inspector, aren\'t we? Inception much? Remember, the deeper you go, the safer you\'ll be!',
  'Just a second...wait a minute...you\'re scanning us?! Well, that\'s an interesting twist! Stay vigilant!',
  'Scanning us? It\'s like asking a mirror to reflect on itself. But keep those scans coming!',
  'Well, this is awkward... like a dog chasing its own tail! But seriously, stay on top of your security game!',
  'Ah, I see you\'re scanning this site... But alas, this did not cause an infinite recursive loop (this time). Keep up the good work!'
];


const SelfScanMsg = () => {
  return (
    <StyledSelfScanMsg>
      <img src="https://i.ibb.co/0tQbCPJ/test2.png" alt="Self-Scan" />
      <b>{messages[Math.floor(Math.random() * messages.length)]}</b>
    </StyledSelfScanMsg>
  );
};

export default SelfScanMsg;
